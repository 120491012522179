.post-section {
  width: 70%;
  .post-card {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .post-title {
      font-size: 14px;
      .post-value {
        color: #727272;
        font-size: 13px;
      }
    }
  }
}
.img-section {
  width: 30%;
  text-align: center;
}

.Post-style {
  p {
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 0 !important;
    color: #727272 !important;
    font-size: 13px !important;
  }
  ul,
  li {
    margin-bottom: 0 !important;
    color: #727272 !important;
    font-size: 13px !important;
  }
}
.img-list-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  background-color: #fff0;
  padding-bottom: 0px;
}

.img-list-img {
  height: 115px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  object-fit: contain;
  padding: 10px;
}
