.image-update-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}


.form-group{
  label{
    font-size: 14px;
    margin-bottom: 5px;
  }
}



.new-post-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-content{
    overflow: auto;
    height: 100%;
  }
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 950px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}
