.ML-Header {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .mobile-hide {
      display: none !important;
    }
    .headerRightCard {
      .table_search_card {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }

  .ant-menu.ant-menu-inline-collapsed {
    width: 0px !important;
  }
  .loginRow {
    justify-content: center;
    position: absolute;
    right: 0;
    left: 0;
    height: 100%;
    align-items: center;
  }
  .table_search_card {
    position: relative;
    margin-right: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      margin-right: 10px;
    }
  }
  .table_search::placeholder {
    color: #9c9c9c;
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    top: 1px;
    right: 2px;
  }
  .table_search {
    max-width: 100%;
    border: 1px solid #2129454d !important;
    border-radius: 100px !important;
    width: 280px;
  }
  .table_search:focus {
    border: 1px solid #2129457a !important;
  }
  .headerTopMenu {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 1px 5px 2px 10px;
    justify-content: space-between;
    width: 100%;
    background: #212945;
    color: #fff;
    font-size: 1rem;
  }
  .header_top-heading {
    font-size: 25px;
    font-weight: 600;
    color: #fcfcfc;
  }
  .header_bottom-heading {
    font-size: 1rem;
    font-weight: 500;
  }
  .sideNavColor {
    // color: #647077;
    color: #b5b5b5;
    font-size: 1rem;
  }
  .sideNavColorSub {
    // color: #647077;
    color: #b5b5b5d4;
    font-size: 15px;
  }
  .nameColor {
    color: #3ea751;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .rowBox {
    width: 600px;
    max-width: 100%;
    border: 1px solid #eee;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
  }
  .form-control {
    background: #fff !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 100%;
    font-size: 15px;
  }
  .form-control:focus {
    box-shadow: none !important;
    border: none;
    border-right: 1px solid #eee;
  }
  .search__Icon {
    font-size: 25px !important;
    margin-top: 0px;
    position: absolute;
    left: -16px;
    color: #c3c3c3;
  }
  .loginHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
  }
  .Button {
    margin-bottom: 20px;
  }
  .accountDropdown:hover {
    border: none !important;
    outline: none !important;
    text-decoration: none !important;
  }
  .accountDropdown:focus {
    border: none !important;
    outline: none !important;
    text-decoration: none !important;
  }
  .accountDropdown:active {
    border: none !important;
    outline: none !important;
    text-decoration: none !important;
  }
  .accountDropdown {
    padding: 0 !important;
    background: #fff0 !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
  }
  // .bdrLft {
  //   margin-left: 20px !important;
  // }
  .dropdown-menu {
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.6rem !important;
    padding-left: 0.5rem !important;
    font-size: 17px !important;
  }
  .headerLogo {
    margin-top: -5px;
  }
  .account_RightDropdown {
    margin-top: 17px !important;
    padding: 0 !important;
    right: 0;
    width: 250px !important;
    min-height: 0px;
    max-height: 500px;
    overflow: auto;
  }
  .module_RightDropdown {
    margin-top: 10px !important;
    padding: 0 !important;
    left: 0;
    width: 250px !important;
    min-height: 0px;
    max-height: 500px;
    overflow: auto;
  }
  .dropdown-item {
    padding: 10px 20px !important;
  }
  .dropdown-menu a:hover {
    background: #31375112 !important;
  }
  .dropdown-menu {
    padding: 0 !important;
  }
  .dropdown-item a:hover {
    text-decoration: none !important;
  }

  .side-navigation-panel::-webkit-scrollbar {
    width: 4px;
    // background-color: #f7f7f7;
    background-color: #414c76;
  }
  .side-navigation-panel::-webkit-scrollbar-thumb {
    background-color: #253261;
  }

  .accountDropdown.dropdown-toggle::after {
    display: none !important;
  }
  .mr-2 {
    background: #f3f2ee !important;
    position: relative !important;
    width: 280px !important;
    height: 40px !important;
  }
  .searchHeader {
    position: relative !important;
    margin-right: 15px;
  }
  // .searchIcon{
  //   position: absolute;
  //   right: 10px;
  //   top: 12px;
  // }
  .dropdownClass {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropDownClass {
    display: flex !important;
    align-items: center !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
  a {
    color: #b8b8b8 !important;
  }
  .dashMargin {
    margin-right: 6px !important;
  }
  .wrapTest {
    display: flex !important;
    flex-wrap: wrap !important;
    font-size: 12px !important;
  }
  .profileAccount {
    background: #fff;
    border-radius: 100%;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;
    line-height: 38px;
    text-transform: uppercase;
    // box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    border: 1px solid #e9e9e9;
  }
  .ntRight {
    margin-right: 10px;
  }
  .headerEmail {
    color: #3b4a53;
  }
  .headerEmaild {
    color: #3b4a53;
    font-weight: 700;
  }
  .hdrusrId {
    color: #3b4a53;
  }
  .hdrDropDsgn {
    padding: 15px 10px 10px 20px;
    border-bottom: 1px solid #eeeeee80;
  }

  .changeStore__btn {
    margin-left: 10px;
  }
  .dropdown-item:focus {
    background-color: #fff0 !important;
  }
  .topNavBar {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 999;
    background-color: #fcfcfc !important;
    padding: 15px 20px 15px 20px !important;
    margin-left: 230px;
    justify-content: space-between;
    height: 55px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 5px !important;
    }
  }

  .BKstyle {
    margin-right: 20px;
  }
  .searchHeader {
    display: flex;
    align-items: center;
  }
  .searchIcon {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #5eb2d9;
    padding: 7px;
    background: #5eb2d9;
    height: 38px;
  }

  .searchInput {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .navbar-brand {
    padding-right: 20px;
    margin-top: -3px;
  }
  .dropHint {
    content: "";
    position: absolute;
    z-index: -1;
    width: 1rem;
    height: 1rem;
    top: -0.375rem;
    right: 1.8rem;
    transform: rotate(45deg);
    background: inherit;
    border-radius: 0.125rem;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #e8e8e8;
  }
  .dropHinta {
    content: "";
    position: absolute;
    z-index: -1;
    width: 1rem;
    height: 1rem;
    top: -0.375rem;
    left: 1.8rem;
    transform: rotate(45deg);
    background: inherit;
    border-radius: 0.125rem;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #e8e8e8;
  }
  .dropdown-menu {
    border: 1px solid #e9e9e9 !important;
  }
  .storeDis {
    font-size: 18px;
  }

  .side-navigation-panel {
    width: 250px !important;
    position: fixed;
    height: 100%;
    // background: #151f25;
    // background: #f7f7f7;
    background: #2b2245;
    border-right: 1px solid #2b2245;
    overflow: auto;
    overflow-x: hidden;
    padding: 55px 0px 50px 0px;
    scroll-behavior: smooth;
    behavior: "smooth";
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option-selected {
    // background: #eeeeee !important;
    background: #eeeeee12 !important;
  }
  .side-navigation-panel-select-option:hover {
    // background: #f8f8f81a !important;
    background: #fff0 !important;
  }
  .side-navigation-panel-select-inner-option:hover {
    background: #fff0 !important;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option-selected {
    background: #fff0 !important;
  }
  .sideManu-hover:hover {
    // background: #eeeeee !important;
    background: #eeeeee12 !important;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option {
    text-align: left !important;
    padding: 0;
    border-left-width: 0px !important;
  }
  .dropIcons {
    font-size: 8px !important;
    margin-right: 8px;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option {
    padding: 0px 15px 0px 0px;
    border-left-width: 0px !important;
  }
  .side-navigation-panel-select-option-text a {
    padding: 10px 13px 10px 13px;
    display: flex;
    align-items: center;
    width: 230px;
    font-size: 15px;
  }
  .side-navigation-panel-select-inner-option a {
    padding: 7px 13px 7px 13px;
    display: flex;
    align-items: center;
    width: 230px;
  }
  .side-navigation-panel-select-inner-option a:hover {
    text-decoration: none;
  }
  .side-navigation-panel-select-option-text a:hover {
    text-decoration: none;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option
    .side-navigation-panel-select-option-wrap
    .side-navigation-panel-select-option-text {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .side-navigation-panel *,
  .side-navigation-panel:after,
  .side-navigation-panel:before {
    border-color: #fff !important;
  }
  .dromMainIcon {
    padding-right: 10px;
    font-size: 28px !important;
  }
  .dromMainD {
    padding: 10px 13px 10px 13px;
    display: flex;
    align-items: center;
    color: #5e6e82 !important;
  }
  .navTophdng {
    width: 218px;
    text-align: left;
    height: 55px;
    display: flex;
    align-items: center;
    font-size: 22px;
    cursor: auto;
    margin-left: 0;
    background: #fafafa;
    top: 0;
    right: 0;
    left: 0;
    padding: 15px;
    z-index: 111;
  }
  .navTophdngcollapsed {
    width: 80px;
  }
  .syncColor {
    padding: 14px;
  }
  .syncIcon {
    cursor: pointer;
    color: #aaa;
    font-size: 24px;
  }
  .navTophdng:hover {
    background-color: #fff0;
  }
  .navbar-collapse {
    justify-content: flex-end;
  }
  .customBtnS {
    color: #444 !important;
    background: #fff !important;
    border: 1px solid #e9e9e9 !important;
  }
  .customBtnS:hover {
    color: #eee !important;
    background: #444 !important;
    //  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    border: 1px solid #f3f2ee;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    // .side-navigation-panel {
    //   display: none;
    // }
    .topNavBar {
      flex-wrap: nowrap;
    }
    .bdrLft {
      display: flex;
      justify-content: flex-end;
    }
    .navbar-collapse {
      text-align: right;
      margin-top: 10px;
    }
  }
  .rds100 {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .srchrds100 {
    border: 1px solid #e9e9e9;
    border-top: none;
    border-bottom: none;
    margin-left: -20px;
    border-radius: 0;
    border-left: none;
    padding-left: 30px;
  }
  .btn {
    font-size: 15px !important;
  }
  .strNamenv {
    color: #5e6e82;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .strNumnv {
    display: flex;
    align-items: center;
  }
  .rotatebeta {
    background-color: #5eb2d9;
    transform: rotate(-90deg);
    position: absolute;
    z-index: 9999;
    right: -18px;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notBdy {
    padding: 10px;
    text-align: center;
  }
  .ntColor {
    color: #5e6e82 !important;
  }
  .boxcard_sy {
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
  }

  .ImgboxN {
    margin-right: 10px;
  }

  .rejected_ordershdr {
    position: absolute;
    height: 22px;
    width: 22px;
    line-height: 22px;
    top: -2px;
    right: 0px;
    background: #e4413d;
    color: #fff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 9999;
  }
  .notFoundn {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 0px) and (max-width: 1080px) {
    .hideOnMob {
      display: none;
    }
  }
  .hrdTopTag {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 99999;
    top: 0;
    width: 230px;
  }
  .hrdTopTag .bg__color {
    background: #fff;
    padding: 10px;
    color: #bb6b11;
    text-align: left;
    display: flex;
    align-items: center;
    width: 259px;
  }
  .hrdTopTag a:hover {
    text-transform: none;
    outline: none;
    border: none;
    text-decoration: none;
  }
  .headerRightCard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    Button {
      small {
        display: flex;
        align-items: center;
      }
    }
  }
  .logoHeader {
    margin: 0;
  }
  .side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option
    .side-navigation-panel-select-inner-option-wrap
    .side-navigation-panel-select-inner-option-text {
    margin: 0 !important;
    padding-left: 0px;
  }
}
table,
tbody,
tr,
th,
td,
p,
small,
a,
span,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}
.headerUserMsg {
  padding: 0px 10px 3px 10px !important;
  margin: 0 !important;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-icon,
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  svg {
  color: #b8b8b8 !important;
}
.dropdown-menu {
  padding: 0 !important;
  border: 1px solid #eee !important;
}
.dropdown-item {
  padding: 5px 15px 5px 15px !important;
}

.sideMenuN {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  width: 230px;
  position: fixed;
  height: 100%;
  background: #222d45 !important;
  border-right: 1px solid #eee;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  behavior: "smooth";
  transition: 0s;
  padding: 10px 0px 10px 0px;
  z-index: 999;
  margin-top: 0px;
  transition: none !important;
}

.sideMenuN {
  a:hover {
    background-color: #ffffff17 !important;
  }
  .thiActive {
    color: #1890ff !important;
    svg {
      color: #1890ff !important;
    }
  }
}

.menuColor {
  span {
    display: flex;
    align-items: center;
  }
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  justify-content: flex-start !important;
  padding: 10px 10px 10px 10px !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  margin-bottom: 7px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  color: #5e6e82 !important;
  border: none !important;
  text-decoration: none !important;
  outline: none !important;
  svg {
    color: #b8b8b8;
    margin: 0px 10px;
  }
}

.sideMenuN {
  .menuColor:hover {
    background: #ffffff17 !important;
    border-radius: 0px !important;
  }
  .menuColor:focus {
    background: #ffffff17 !important;
    border-radius: 0px !important;
    border-right: 1px solid #ffffff17 !important;
  }
}

.ant-menu-submenu-title:focus {
  background-color: #f0f0f0 !important;
}

.subMenuColor {
  color: #444 !important;
}

.menuCollapsed {
  margin-left: 0px !important;
}
.bodyCollapsed {
  @media only screen and (min-width: 0px) and (max-width: 1080px) {
    margin-left: 0px !important;
  }
  margin-left: 0px !important;
}
.hideHeaderName {
  display: none;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  color: #b5b5b5;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff1f !important;
}

.ant-menu-submenu-title {
  display: flex;
  align-items: center;
  color: #b5b5b5 !important;
}
.dFlex {
  display: flex;
  align-items: center;
}
.subMenuColor .ant-menu-title-content {
  color: #444;
}
.ant-menu-item,
.ant-menu-submenu-title {
  font-size: 15px;
  transition: border-color 0s, background 0s,
    padding 0s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  transition: opacity 0s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0s,
    color 0s !important;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  transition: font-size 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    margin 0s cubic-bezier(0.645, 0.045, 0.355, 1), color 0s !important;
}

.accountModal {
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 270px;
  top: 54px;
  margin: 0;
  min-height: 0;
  background-color: #fff;
  box-shadow: "1px 3px 1px #9E9E9E";
  padding: 15px;
  border: 1px solid #e9e9e9 !important;
  border-top: none;
  .icon {
    display: flex;
    justify-content: right;
    font-size: 18px;
  }
  .accountMenu {
    width: 100%;
    padding: 5px 15px 5px 15px;
    display: flex;
    align-items: center;
  }
}
.blockAccountModal {
  display: block;
}
.hideAccountModal {
  display: none;
}
.click-account {
  cursor: pointer;
}

.accountHeader {
  margin-top: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.accountProfileName {
  font-size: 14px;
}
.subaccountcart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 5px 0px;
  background: #22294512;
  text-align: center;
  margin-top: 10px;
}
.accountRoleCard {
  border-right: 1px solid #2229453b;
  padding-right: 10px;
  font-size: 1rem;
  text-transform: capitalize;
  width: 100%;
}
.accountLOCard {
  padding-left: 10px;
  font-size: 1rem;
  width: 100%;
}

.addAllIconCard {
  display: flex;
  align-items: center;
  font-size: 1rem;
  .addAllIcon {
    color: #2b2245;
    font-size: 26px;
  }
}
.headerIconsRight {
  margin-right: 26px;
  font-size: 20px;
  color: #666666;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  font-size: 20px !important;
}

.sideMenuIcon {
  font-size: 18px !important;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0 calc(50% - 11px) !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 16px !important;
  padding: 8px 12px !important;
}

.ant-menu.ant-menu-inline-collapsed {
  @media only screen and (min-width: 0px) and (max-width: 1080px) {
    width: 0px !important;
  }
}

.loading-progress-bg {
  .MuiLinearProgress-colorPrimary {
    background-color: #222d452b !important;
    .MuiLinearProgress-barColorPrimary {
      background-color: #222d45 !important;
    }
  }
}

.reject-bottom-message {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 111;
}
