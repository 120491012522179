.image-update-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}


.form-group{
  label{
    font-size: 14px;
    margin-bottom: 5px;
  }
}



.new-post-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-content{
    overflow: auto;
    height: 100%;
  }
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 950px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}







.tabel_cards {
  .orders-page-item-link:hover {
    border: 1px solid #007bff !important;
  }

  .orders-sub-side-menu {
    font-size: 14px;
    display: block;
    text-transform: capitalize;
    border-bottom: 1px solid rgb(238, 238, 238);
    width: 100%;
    padding: 7px 15px 7px 15px;
    border-radius: 0 !important;
    font-weight: 400 !important;
  }
  .productMaincard {
    padding: 15px !important;
  }
  .table_search {
    height: 38px;
    min-width: 240px;
    border-color: hsl(0, 0%, 80%);
  }
  .table_search:focus {
    border-color: hsl(0, 0%, 80%);
  }
  .css-1pahdxg-control {
    box-shadow: none !important;
  }
  .css-1pahdxg-control:hover {
    border-color: hsl(0, 0%, 80%) !important;
  }
  .css-1pahdxg-control:focus {
    border-color: hsl(0, 0%, 80%) !important;
  }
  .css-1pahdxg-control:active {
    border-color: hsl(0, 0%, 80%) !important;
  }
  .datePicker {
    height: 40px;
    border: 1px solid #e9e9e9 !important;
  }
  .table_search_card {
    position: relative;
    display: flex;
    align-items: center;
  }

  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    top: 11px;
    right: 10px;
  }
  .S_Card-Heading {
    font-weight: 300;
    margin: 0;
  }
  .tabelOverflow {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    position: relative;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 10px 14px 10px 14px !important;
    color: #5c5e5f;
    font-size: 14px;
    background: #f8f8f7;
  }

  .table thead th {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .table {
    // margin-bottom: 0 !important;
    box-shadow: none !important;
  }

  th:first-child {
    width: 10px;
  }
  th:nth-child(2) {
    width: 10px;
  }

  .dropdown .dropDown_Btn {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #e9e9e9;
    color: #444;
    justify-content: center;
    padding: 8px;
  }
  .dropdown .dropdown-toggle-split {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #e9e9e9;
    color: #444;
    justify-content: center;
    padding: 8px;
  }

  .productSubcard {
    border: 1px solid #eff1f3;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .productSubcard:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02) !important;
  }

  .displayFlex {
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .storeCard {
    padding: 30px;
    background-color: #fff;
  }
  .storeCardB {
    padding: 0px;
  }
  .storeCardH {
    padding: 0px;
  }
  .filDisF {
    display: flex;
    align-items: center;
  }
  .dFlexL {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .dFlex {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .sendStatus {
    background: #80c788;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 1px 8px 3px 8px;
  }

  .cardImage {
    margin-right: 5px;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 10%;
    padding-right: 20px;
  }

  .cardImages {
    height: 60px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 2;
  }

  .cardTitle {
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 5px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2e2f31;
    line-height: 1.2;
    width: 90%;
  }

  .manageProducts {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    background: #f3f2ee;
  }

  .manageButtons {
    margin-right: 10px;
  }

  .manageButtonsmrgn {
    margin-right: 0px;
    border-right: 1px solid #c3bfbf;
    color: #444 !important;
    padding: 0px 10px 0px 10px;
    font-size: 12px;
  }

  .spinLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5eb2d9;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .spinLoading {
      padding-left: 0px;
    }
  }

  .updateButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    // overflow: auto;
    white-space: nowrap;
    width: 100%;
    background: #f8f8f7;
  }

  .infoCard {
    display: flex;
    align-items: center;
  }

  .updateIcon {
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  .fsize12 {
    display: flex;
    font-size: 12px;
    padding-left: 20px;
  }
  .pagiStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
  }

  .subPagi {
    margin-left: 1px;
  }
  .btn-group-sm > .btn {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
  }
  .card-header {
    text-align: right;
    padding: 0;
    border: none;
  }

  .card {
    border: none;
    border-bottom: 1px solid #e0e0e0ee;
  }

  .det_Sty {
    text-align: left;
    padding: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    border-bottom: 1px solid #eee;
  }
  .detialsView {
    cursor: pointer;
    padding: 10px;
    background: #fff0;
  }
  .pageSz {
    margin-right: 20px;
  }

  .fileUpload {
    padding: 7px;
    font-size: 12px;
    margin-bottom: 10px;
    height: 40px;
  }
  .FormGroupmrgn {
    margin: 0;
  }
  .successHeadng {
    color: #28a745;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .errorHeadng {
    color: #dc3545;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .uploadSpin {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .viewMr {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 12px;
    z-index: 111;
  }
  .upldInv {
    text-align: left;
    padding-left: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #17a2b8;
  }
  .newFlex {
    display: flex;
    align-items: center;
  }
  .newFlex2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #eee;
  }
  .lastBorder {
    border-right: none !important;
  }
  .subPagi Button {
    border: none;
    border-right: 1px solid #eee;
    background-color: #fff !important;
    color: #444 !important;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    height: 27px;
  }
  .subPagi Button:hover {
    background-color: #eee !important;
    color: #444 !important;
    border: 1px solid #e9e9e9 !important;
  }
  .subPagi Button:focus {
    outline: none !important;
  }
  .newFlexPagi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
    padding: 10px 0px 10px 0px !important;
    background: #fcfcfc;
    border-top: 1px solid #eee;
  }
  .txtcntr {
    text-align: left;
    margin-top: 10px;
  }
  .upldInv {
    justify-content: space-between;
  }
  .BrdrRound {
    position: relative;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .cardImage {
      width: 100%;
      margin-top: 10px;
    }
    .displayFlex {
      display: block;
    }
    .cardTitle {
      width: 100%;
      margin-top: 10px;
    }
    .manageProducts {
      overflow: auto;
      white-space: nowrap;
    }
    .stchLeft {
      padding-right: 10px;
    }
    .hdMob {
      display: none;
    }
    .hdDask {
      display: block !important;
    }
    .pagiStyle {
      overflow: auto;
      white-space: nowrap;
    }
    .mbSzAdd {
      text-align: left;
      display: block;
    }
  }
  .manageProducts {
    overflow: auto;
    white-space: nowrap;
  }

  .btnUploadIn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .table {
    text-align: left;
    box-shadow: 0 0px 10px #ccc;
  }
  .listHeadng {
    margin: 0;
    text-align: center;
  }
  .actionBDiv {
    display: flex;
    align-items: center;
  }
  .actionButton {
    margin-right: 10px;
  }
  .actionButtonIcon {
    font-size: 14px;
  }
  .brdrLdng {
    border-top: 6.5px solid rgb(248, 248, 248);
  }
  .mrgnRight {
    margin-right: 10px;
  }
  .pdngStyle {
    font-size: 13px;
  }
  .crtCamp {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  .crtCampS {
    display: flex;
    align-items: center;
  }
  .afterScheduled {
    display: flex;
    align-items: center;
  }
  .shcldLft {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .campSpace {
    margin-top: 7px;
  }
}

.dlrdflInput {
  height: 50px !important;
}
.dlrdflUpload {
  height: 50px !important;
  padding: 9px !important;
}
.fileUploadmsg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new_Modal {
  .modal-dialog {
    max-width: 40% !important;
  }
  .modal-body {
    padding: 1rem !important;
  }
}

.sticky-column {
  right: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  z-index: 111;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.data_not-found {
  font-size: 15px;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  padding: 20px 0px 20px 0px;
}
.list-paginatiion-table {
  ul {
    flex-wrap: nowrap;
    overflow: auto;
  }
}

.image-update-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
}

.new-post-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-content {
    overflow: auto;
    height: 100%;
  }
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 950px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
}

.view-items-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-content {
    overflow: auto;
    height: 100%;
  }
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 1130px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 0rem;
  }
  .image-update-button {
    width: 100%;
    justify-content: center;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 10px 14px 10px 14px !important;
    color: #5c5e5f;
    font-size: 13px;
    background: #f8f8f7;
  }
  .table thead th {
    border-bottom: 1px solid #dee2e6 !important;
  }
}

